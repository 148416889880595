
import IComponentProps from "../../../interfaces/IComponentProps";
import stylesBase from "./Locator.module.scss";
import LocatorModel from "../../../models/LocatorModel";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import {APIProvider, Map, MapCameraChangedEvent, AdvancedMarker } from '@vis.gl/react-google-maps';
import youAreHere from "../../_assets/current-position.png"
import LocationInfo from "./LocationInfo";
import { connect } from "react-redux";
import { addValues, updateLocation, updateLocationIndex } from "../../../redux/slices/pageContentSlice";
import { RootState } from "../../../redux/store";
import { openModal } from "../../../redux/slices/modalSlice";
import { ILocation, IMapDispatchProps, IPageRedux, ISearchParams } from "./LocationInterfaces";
import { clickEvent } from "../../../models/SmartLinkModel";
import { LocatorMarkers, isOpenToday, openTodayHeading, openTodayText } from "./LocatorMarkers";
import debounce from "lodash.debounce";
import { useState } from "react";
import { useLocationsMutation } from "../../../redux/_shared/helpers/vystarApi";
import SmartLink from "../_shared/SmartLink/SmartLink";
import { HtmlDiv } from "./HtmlDiv";

export const mapStateToProps = (state: RootState, _: any): any => {
    const { location: currentLocation, currentLat, currentLng, currentZoom, locationIndex, isVystarBranchFilterActive, 
        isVystarAtmFilterActive, isSurchargeFreeAtmFilterActive, isDepositOnly, googleSteps, locations, isUpdateFromListOrMarker } = state.pageContent;
    return { currentLocation, currentLat, currentLng, currentZoom, locationIndex, isVystarBranchFilterActive, isUpdateFromListOrMarker,
        isVystarAtmFilterActive, isSurchargeFreeAtmFilterActive, isDepositOnly, googleSteps, locations };
};

export const mapDispatchToProps = (dispatch: any, _: any):IMapDispatchProps => {
    return {
        valuesAdd : (payload: any)=>{
            dispatch(addValues(payload));
        },
        locationIndexUpdate: (payload: number)=>{
            dispatch(updateLocationIndex(payload));
        },
        locationUpdate: (payload: any)=>{
            dispatch(updateLocation(payload));
        },
        modalOpen: (payload: any)=>{
            dispatch(openModal(payload));
        },
    };
};

const setPosition = (reduxSender: Function, map: any) => {

    let payload = {
        currentLat: 30.253092900000002,
        currentLng: -81.7413555,
        locationIndex: -1
    }

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            function (position) {
                const { latitude, longitude } = position.coords;
                payload = {
                    currentLat: latitude,
                    currentLng: longitude,
                    locationIndex: -1
                }
                reduxSender(payload);
                map.panTo({lat: latitude, lng: longitude})
            },
            function (error) {
                console.log("Location tracking not enabled", error);
                reduxSender(payload);
            },
            { timeout: 30000, enableHighAccuracy: true, maximumAge: 75000 }
        );
    } else {
        reduxSender(payload);
    }
}


const getSearchParams = (currentLat: number, currentLng: number, isDepositOnly: boolean, branchFilter: boolean, vcuAtmFilter: boolean, surchargeFreeFilter: boolean) => {
    let type = "";
    if (!branchFilter) {
        type += "FCS,";
    }
    if (!vcuAtmFilter) {
        type += "ATMDP,";
    }
    if (!surchargeFreeFilter) {
        type += "ATMSF,ATMPLUS,";
    }
    var searchParams = {
        "Latitude": currentLat,
        "Longitude": currentLng,
        "Type": type,
        "SubType": isDepositOnly ? "DepositOnly" : ""
    };
    return searchParams;
}
const setValueWithGeoCoder = (reduxFunction : Function, map: google.maps.Map | null) => {
    var geocoder = new google.maps.Geocoder();
    var inputSearch = document.getElementById("locations-search-city-zip") as HTMLInputElement;
    const { value } = inputSearch || {value: ""};
    geocoder.geocode({address:value}, function (results, status) {
        if(results?.length){
            map?.panTo(new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng()));
            const payload = {
                currentLat: results[0].geometry.location.lat(),
                currentLng: results[0].geometry.location.lng()
            };
            reduxFunction(payload);
        }

    });
}
const sendEventDebounced = debounce((ev: any, updateFunction: Function, fetchDataFunc: Function, searchParams: ISearchParams, isUpdateFromListOrMarker: boolean) => {

    // if event doesn't come from the list or marker (isUpdateFromListOrMarker), we want to recenter the "you are here" pin by setting currentLat and currentLng
    // if event doesn't come from the list or marker (isUpdateFromListOrMarker), we also want new data

    if(isUpdateFromListOrMarker){
        updateFunction({isUpdateFromListOrMarker: false});
    }else{
        const fetchParams = {
            ...searchParams,
            Latitude: ev.detail.center.lat,
            Longitude: ev.detail.center.lng,
        };
        fetchDataFunc(fetchParams).then((resp: any)=>{
            if(resp.data?.length){
                const payload = {
                    locations: resp.data,
                    locationIndex: -1,
                    currentLat: ev.detail.center.lat,
                    currentLng: ev.detail.center.lng,
                }
                updateFunction(payload);
            }
        });
    }
}, 500);

export function Locator(props:  LocatorModel & IComponentProps & IPageRedux) {

    var today = new Date();
    var dayOfWeek = today.getDay();
    const [ map, setMap ] = useState< google.maps.Map | null>(null);

    const {header, id, locations = [], currentLocation, currentLat, currentLng, isDepositOnly, locationUpdate, modalOpen, isUpdateFromListOrMarker, locationIndexUpdate, locationIndex,
        isVystarBranchFilterActive, isVystarAtmFilterActive, isSurchargeFreeAtmFilterActive, valuesAdd} = props;

    const searchParams = getSearchParams(currentLat, currentLng, isDepositOnly, isVystarBranchFilterActive, isVystarAtmFilterActive, isSurchargeFreeAtmFilterActive);

    const areAllFiltersActive = !!searchParams.Type && !!locations?.length;

    // initial loading of position using geolocation
    const [isLoaded, setIsLoaded] = useState(false);
    const [getLocation] = useLocationsMutation();

    if(!isLoaded && !!map){
        setPosition(valuesAdd, map);
        setIsLoaded(true);
    }

    const hasCurrentLatLng = !!(currentLat && currentLng);

    return (
        <div className={`layout ${stylesBase.locator}`} data-contentid={id} onKeyDown={(e: any)=>{
            if(e.keyCode === 13){
                setValueWithGeoCoder(valuesAdd, map);
            }
        }}>
            <div className="container">
                <h1>{header}</h1>
                <div className="row g-0">
                    <div className="col">
                        <div className={`${stylesBase.iframe}`}>
                            <div className={`${stylesBase.locations}`} >
                            <div className={`${stylesBase["locations-search"]} text-shadow`}>
                                <div className="row">
                                    <div className="col-xs-6 col-sm-3 col-lg-2">
                                        <label htmlFor="locations-search-city-zip" style={{display:"none"}} className="visuallyhidden">
                                            Enter City, State, or Zip
                                        </label>
                                        <input className={`${stylesBase["inptsearch"]} ${stylesBase["form-control"]}`} type="text" id="locations-search-city-zip" placeholder="City, State or Zip" data-maxzpsw="0"/>
                                    </div>
                                    <div className="col-xs-6 col-sm-3 col-lg-2">
                                        <button className={`btn ${stylesBase["btn"]} ${stylesBase["btn-ss"]} text-left`} id="locations-search-submit"  onClick={()=>{
                                            setValueWithGeoCoder(valuesAdd, map);
                                        }}>
                                            Search
                                        </button>
                                    </div>
                                    <div className="col-xs-9 col-sm-12 col-lg-8 align-middle" data-toggle="buttons" id="btn-row" aria-label="Search Filter">
                                        <label className={`${stylesBase["btn-secondary"]} ${stylesBase["btn"]} ${isVystarBranchFilterActive ? stylesBase['filter'] : ''}`}>
                                            <input type="checkbox" id="btn-filter-branch" onChange={(e: any)=>{
                                                const filterPayload = {
                                                    isVystarBranchFilterActive: e.target.checked
                                                }
                                                valuesAdd(filterPayload);
                                                const newSearchParams = getSearchParams(currentLat, currentLng, isDepositOnly, e.target.checked, isVystarAtmFilterActive, isSurchargeFreeAtmFilterActive)
                                                getLocation(newSearchParams).then((resp:any)=>{
                                                    if(resp.data?.length){
                                                        const payload = {
                                                            locations: resp.data,
                                                            
                                                        }
                                                        valuesAdd(payload);
                                                    }
                                                })
                                            }} />
                                            <span className={`${stylesBase["branch-icon"]}`}></span>
                                            <span className={`${stylesBase["lbl-filter"]} align-middle`}>&nbsp;VyStar Branch</span>
                                        </label>
                                        <label className={`${stylesBase["btn-secondary"]} ${stylesBase["btn"]} ${isVystarAtmFilterActive ? stylesBase['filter'] : ''}`}>
                                            <input type="checkbox" id="btn-filter-vystar-atm" onChange={(e: any)=>{
                                                const payload = {
                                                    isVystarAtmFilterActive: e.target.checked
                                                }
                                                valuesAdd(payload);
                                                const newSearchParams = getSearchParams(currentLat, currentLng, isDepositOnly, isVystarBranchFilterActive, e.target.checked, isSurchargeFreeAtmFilterActive);
                                                getLocation(newSearchParams).then((resp:any)=>{
                                                    if(resp.data){
                                                        const payload = {
                                                            locations: resp.data,
                                                            
                                                        }
                                                        valuesAdd(payload);
                                                    }
                                                })
                                            }} />
                                            <span className={`${stylesBase["vystar-atm-icon"]}`}></span>
                                            <span className={`${stylesBase["lbl-filter"]} align-middle`}>&nbsp;VyStar ATM</span>
                                        </label>
                                        <label className={`${stylesBase["btn-secondary"]} ${stylesBase["btn"]} ${isSurchargeFreeAtmFilterActive ? stylesBase['filter'] : ''}`}>
                                            <input type="checkbox" id="btn-filter-surcharge-free-atm" onChange={(e: any)=>{
                                                const payload = {
                                                    isSurchargeFreeAtmFilterActive: e.target.checked
                                                }
                                                valuesAdd(payload);
                                                const newSearchParams = getSearchParams(currentLat, currentLng, isDepositOnly, isVystarBranchFilterActive, isVystarAtmFilterActive, e.target.checked);
                                                getLocation(newSearchParams).then((resp:any)=>{
                                                    if(resp.data?.length){
                                                        const payload = {
                                                            locations: resp.data,
                                                            
                                                        }
                                                        valuesAdd(payload);
                                                    }
                                                })                                            }} />
                                            <span className={`${stylesBase["surcharge-free-atm-icon"]}`}></span>
                                            <span className={`${stylesBase["lbl-filter"]} align-middle`}>&nbsp;Surcharge-Free ATM</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={`${stylesBase["locations-results"]}`}>
                                <div className={`${stylesBase["results-map"]}`}>
                                    <APIProvider apiKey={'AIzaSyB6vE8x044E7IT3_fX_q5kT0y6kfBd09bY'} onLoad={() => console.log('Maps API has loaded.')} >
                                        <Map
                                            defaultZoom={13}
                                            mapId={"LocationMap"}
                                            defaultCenter={ { lat: currentLat, lng: currentLng } }
                                            onIdle={(ev: any)=>{
                                                setMap(ev.map);
                                            }}
                                            onCameraChanged={ (ev: MapCameraChangedEvent) => {
                                                sendEventDebounced(ev, valuesAdd, getLocation, searchParams, isUpdateFromListOrMarker);
                                            }}>
                                            {hasCurrentLatLng &&<AdvancedMarker position={{lat: currentLat, lng: currentLng}} title={"Your Current Position"}>
                                                <img src={youAreHere} alt="You are here."/>
                                            </AdvancedMarker>}
                                            {!!locations && <LocatorMarkers 
                                                locations={locations} 
                                                activeIndex={locationIndex} 
                                                valuesAdd={valuesAdd}
                                                updateLocation={locationUpdate} 
                                                locationIndexUpdate={locationIndexUpdate}
                                                map={map}
                                                dayOfWeek={dayOfWeek} />}
                                        </Map>
                                    </APIProvider>
                                    <div
                                        id="map-canvas"
                                        style={{
                                            position: "relative",
                                            overflow: "hidden"
                                        }}
                                    >
                                        <div style={{
                                            height: "100%",
                                            width: "100%",
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            backgroundColor: "rgb(229, 227, 223)"
                                            }}
                                        >
                                            <div>
                                                <button
                                                    draggable="false"
                                                    aria-label="Keyboard shortcuts"
                                                    title="Keyboard shortcuts"
                                                    type="button"
                                                    style={{
                                                        backgroundColor: "none transparent",
                                                        display: "block",
                                                        border: "none",
                                                        margin: 0,
                                                        padding: 0,
                                                        textTransform: "none",
                                                        appearance: "none",
                                                        position: "absolute",
                                                        cursor: "pointer",
                                                        userSelect: "none",
                                                        zIndex: 1000002,
                                                        outlineOffset: 3,
                                                        right: 0,
                                                        bottom: 0,
                                                        transform: "translateX(100%)"
                                                    }}>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className={`${stylesBase["results-list"]}`} id="locations-list">
                                    <div className={`${stylesBase["results-header"]}`} id="locations-header">
                                        {areAllFiltersActive ? (<div className={`${stylesBase["title"]}`}><span>{locations.length} Results</span></div>
                                        ):                                        
                                        <div className={`${stylesBase["no-results"]}`} >
                                            <span>
                                            No results were found, select a filter to retrieve locations
                                            </span>
                                        </div>
                                        }
                                        {areAllFiltersActive && <div id="deposit-only-atms-container">
                                            <input type="checkbox" id="deposit-only-atms" onChange={(e:any)=>{
                                                const payload = {
                                                    isDepositOnly: e.target.checked
                                                }
                                                valuesAdd(payload);
                                                const newSearchParams = getSearchParams(currentLat, currentLng, e.target.checked, isVystarBranchFilterActive, isVystarAtmFilterActive, isSurchargeFreeAtmFilterActive);
                                                getLocation(newSearchParams).then((resp:any)=>{
                                                    if(resp.data?.length){
                                                        const payload = {
                                                            locations: resp.data,
                                                            
                                                        }
                                                        valuesAdd(payload);
                                                    }
                                                })    
                                            }} />
                                            <label htmlFor="deposit-only-atms" className={`${stylesBase["loc-sub"]}`}>Show only deposit-taking ATM</label>
                                        </div>}
                                    </div>
                                    <ul>
                                        {areAllFiltersActive ? locations?.map((location: ILocation, locationInstanceIndex: number)=>{

                                            const { latitude,
                                                longitude,
                                                name,
                                                address,
                                                city,
                                                state,
                                                zipcode,
                                                websiteUrl,
                                                monday, 
                                                tuesday, 
                                                wednesday, 
                                                thursday, 
                                                friday, 
                                                saturday, 
                                                sunday, 
                                                locationType,
                                                driveThruHours,
                                                retailOutlet
                                                } = location;
                                                const openTodayString = openTodayText(dayOfWeek, monday, tuesday, wednesday, thursday, friday, saturday, sunday);
                                                const openTodayHeader = openTodayHeading(dayOfWeek);
                                                const isOpen = isOpenToday(locationType, dayOfWeek);

                                            return (
                                                <li
                                                    tabIndex={locationInstanceIndex}
                                                    id={`location-${locationInstanceIndex.toString()}`}
                                                    data-latitude={latitude}
                                                    data-longitude={longitude}
                                                    key={locationInstanceIndex}
                                                    className={locationIndex === locationInstanceIndex ? stylesBase["active"] : undefined}
                                                    onClick={(e)=>{
                                                        const scrollList = document.getElementById("locations-list");
                                                        if(scrollList){
                                                            scrollList!.scrollTo({top: e.currentTarget.offsetTop, behavior: 'smooth'});
                                                        }
                                                        locationIndexUpdate(locationInstanceIndex);
                                                        locationUpdate(location);
                                                        // if you scroll from the list don't update youAreHere marker
                                                        const payload = {
                                                            isUpdateFromListOrMarker: true
                                                        }
                                                        valuesAdd(payload);
                                                        map?.panTo({lat: latitude, lng: longitude});
                                                    }}
                                                >
                                                    <div className={`${stylesBase["number"]}`}>{locationInstanceIndex + 1}</div>
                                                        <div className={`${stylesBase["content"]}`}>
                                                            <div>
                                                                <span className={`${stylesBase["branch-name"]}`}>{name}</span>
                                                            </div>
                                                            {retailOutlet && <HtmlDiv className={`${stylesBase["under-maint"]}`} innerHTML={'* ' + retailOutlet} />}                                                            <div className={`${stylesBase["address"]}`}>
                                                                <div>
                                                                    <span className={`${stylesBase["get-directions-address"]}`}>{address}</span>
                                                                </div>
                                                                <div>
                                                                    <span className={`${stylesBase["get-directions-city"]}`}>{city}</span>,
                                                                    <span className={`${stylesBase["get-directions-state"]} ms-1`}>{state}</span>
                                                                    <span className={`${stylesBase["get-directions-zipcode"]}`}>{zipcode}</span>
                                                                </div>
                                                            </div>
                                                            { isOpen && <div>
                                                                <br />
                                                                <span>
                                                                    <strong className={`${stylesBase["bolder"]}`}>
                                                                        {openTodayHeader}
                                                                        <br />
                                                                    </strong>{openTodayString}
                                                                </span>
                                                                <br />
                                                            </div>}
                                                            { !!driveThruHours && <div>
                                                            <br />
                                                            <span className="get-directions-additionalinfo1">
                                                                <b className={`${stylesBase["bolder"]}`}>
                                                                    Drive-Thru Hours
                                                                </b><br />
                                                                <HtmlDiv innerHTML={driveThruHours} />
                                                            </span>
                                                        </div>}
                                                            <div className={`${stylesBase["get-directions"]}`}>
                                                                <a className={`${stylesBase["get-directions-popup"]}`} onClick={(e:clickEvent)=> {
                                                                    e.preventDefault();
                                                                    const payload = {
                                                                        location
                                                                    }
                                                                    locationUpdate(payload);
                                                                    modalOpen({modalType: "Location"});
                                                                }}>
                                                                    Get Directions
                                                                </a><br />
                                                                {websiteUrl &&<SmartLink className={`${stylesBase["get-directions-popup"]}`} url={websiteUrl} defaultLinkText={"Schedule an Appointment"} />}
                                                            </div>
                                                    </div>
                                                </li>
                                            );
                                        }):<li></li>}
                                    </ul>
                                </div>
                            </div>
                            <LocationInfo {...currentLocation}/>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps) (withErrorBoundary(Locator));